import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/icons/Menu";

import { getSession, deleteSession } from "../../utils/session";
import useStyles from "./styles";

const TopBar = ({ toggleMenu, title, drawerWidth }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopoverClick = (event) => setAnchorEl(event.currentTarget);

  const handleClosePopoverClick = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    deleteSession();
    history.push("/login");
  };

  const open = Boolean(anchorEl);
  const { user } = getSession() || {};
  const { username } = user || {};

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <Button onClick={() => toggleMenu(true)}>
            <Menu className={classes.icon} />
          </Button>
        </Box>
        <Typography variant="h6" color="inherit" className={classes.title}>{title}</Typography>
        {user && (
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleOpenPopoverClick}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        )}
        <Popover
          id="user-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopoverClick}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography className={classes.userPopover}>{`${username}`}</Typography>
        </Popover>
        {user && (
          <Button color="inherit" className={classes.logout} onClick={handleLogoutClick}>Logout</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};

export default TopBar;
