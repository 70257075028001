const roles = {
  admin: "Admin",
  manager: "Manager",
  supervisor: "Supervisor",
  worker: "Worker",
};

const managerRoles = {
  manager: "Manager",
  supervisor: "Supervisor",
  worker: "Worker",
};

export {
  roles,
  managerRoles,
};
