import config from "../config";

const requestApi = async (
  path = "",
  method = "GET",
  data = null,
  headers = {},
) => {
  if (!config?.domains?.api) {
    throw new Error("Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the \"site\" folder, in the \"./config.js\" file.  Instructions are listed there and in the documentation.");
  }

  const updatedPath = !path.startsWith("/") ? `/${path}` : path;
  const url = `${config.domains.api}${updatedPath}`;

  const updatedHeaders = { "Content-Type": "application/json", ...headers };

  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: "cors",
    cache: "no-cache",
    headers: updatedHeaders,
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json();
    throw new Error(error.error);
  }

  if (response.status === 204) return true;

  const result = await response.json();
  return result;
};

export { requestApi };
