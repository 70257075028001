const menu = {
  // devices: { name: "Dashboard", path: "/" },
  // deviceManagement: { name: "Devices", path: "/devices" },
  users: { name: "Users", path: "/users" },
  workers: { name: "Users", path: "/workers" },
  transactions: { name: "Transactions", path: "/transactions" },
  pageNotFound: { name: "Page not found", path: "/page-not-found" },
};

const permissions = {
  admin: {
    routes: ["users", "transactions"], // "devices", "deviceManagement",
    default: "users",
  },
  manager: {
    routes: ["workers"], // "devices"
    default: "workers",
  },
  supervisor: {
    routes: [],
    default: "pageNotFound",
  },
  worker: {
    routes: [],
    default: "pageNotFound",
  },
};

export {
  permissions,
  menu,
};
