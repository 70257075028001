import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import PublicRoute from "./router/PublicRoute";
import RoleBasedRouting from "./router/RoleBasedRouting";

import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout";

import Users from "./pages/Users";
import Login from "./pages/Login";
import Transactions from "./components/Transactions";
import Workers from "./pages/Workers";
// import Devices from "./pages/Devices";
// import DevicesManagement from "./pages/DevicesManagement";
// <RoleBasedRouting exact path="/" component={Devices} title="Dashboard" layout={MainLayout} />
// <RoleBasedRouting exact path="/devices" component={DevicesManagement}
// title="Devices" layout={MainLayout} />

const App = () => (
  <Router>
    <Switch>
      <PublicRoute exact path="/login" component={Login} title="Login" layout={LoginLayout} />
      <RoleBasedRouting exact path="/users" component={Users} title="Users" layout={MainLayout} />
      <RoleBasedRouting exact path="/transactions" component={Transactions} title="Transactions" layout={MainLayout} />
      <RoleBasedRouting exact path="/workers" component={Workers} title="Workers" layout={MainLayout} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default App;
