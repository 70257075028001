import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    minWidth: "170px",
  },
  selectedMenuItem: {
    fontWeight: "bold",
  },
}));

export default useStyles;
