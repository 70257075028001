import { requestApi } from "../utils/request";
import { getSession } from "../utils/session";

const userRegister = async (email, password) => requestApi("/users/register", "POST", { email, password });

const userLogin = async (username, password) => requestApi("/users/web-login", "POST", { username, password });

const userGet = async (token) => requestApi("/users/current", "GET", null, {
  Authorization: `Bearer ${token}`,
});

const userGetList = async (excludeRoles) => {
  const { userToken } = getSession();
  return requestApi("/users/list", "POST", { excludeRoles }, {
    Authorization: `Bearer ${userToken}`,
  });
};

const userCreate = async (user) => {
  const { userToken } = getSession();
  return requestApi("/users", "POST", user, {
    Authorization: `Bearer ${userToken}`,
  });
};

const userUpdate = async (user) => {
  const { userToken } = getSession();
  return requestApi("/users", "PUT", user, {
    Authorization: `Bearer ${userToken}`,
  });
};

const userDelete = async (username) => {
  const { userToken } = getSession();
  return requestApi("/users", "DELETE", { username }, {
    Authorization: `Bearer ${userToken}`,
  });
};

export {
  userRegister, userLogin, userGet, userGetList, userCreate, userDelete, userUpdate,
};
