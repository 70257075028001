import React, { useEffect, useState } from "react";
import {
  FormControl, InputLabel, MenuItem, Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { userGetList } from "../../api/user.api";
import { txExportByUserEmail } from "../../api/transaction.api";

const Transactions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), 1));
  const [toDate, setToDate] = useState(new Date(now.getFullYear(), now.getMonth() + 1, 0));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const data = await userGetList();
      setUsers(data);
      if (data.length > 0) {
        setSelectedUserId(data[0]?.id);
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const handleDownloadClick = async () => {
    setError("");
    const selectedUser = users.find(({ id }) => id === selectedUserId);
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    const timeOffsetInMS = 6 * 60 * 60000;
    const currentTimeOffset = fromDate.getTimezoneOffset() * 60000;
    const convertedFromDate = fromDate.getTime() - currentTimeOffset + timeOffsetInMS;
    const convertedToDate = toDate.getTime() - currentTimeOffset + timeOffsetInMS;
    const { downloadURL } = await txExportByUserEmail(
      selectedUser.email,
      convertedFromDate,
      convertedToDate,
    );
    if (!downloadURL) {
      setError("Can not generate a report");
    } else {
      const a = document.createElement("a");
      a.href = downloadURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div style={{ height: 700, maxWidth: 880 }}>
      {isLoading
        ? <CircularProgress style={{ display: "block", margin: "0 auto" }} />
        : (
          <FormControl sx={{ m: 1, width: 300 }}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <InputLabel id="user-label">User</InputLabel>
                <Select
                  labelId="user-label"
                  id="user-multiple-checkbox"
                  value={selectedUserId}
                  label="User"
                  onChange={({ target }) => setSelectedUserId(target.value)}
                  style={{ width: "100%" }}
                >
                  {users.map(({
                    id, firstName, lastName, email,
                  }) => (
                    <MenuItem
                      value={id}
                    >
                      {`${firstName} ${lastName} <${email}>`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="From"
                    format="dd/MM/yyyy"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="To"
                    format="dd/MM/yyyy"
                    value={toDate}
                    onChange={(date) => { setToDate(date); setIsFormValid(true); }}
                    minDate={fromDate}
                    onError={(errorMsg) => setIsFormValid(errorMsg === "")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "10px" }}
                  startIcon={<SaveIcon />}
                  onClick={handleDownloadClick}
                  disabled={!isFormValid}
                >
                  Download excel file
                </Button>
                {error && <Typography variant="body2" color="error" style={{ marginTop: "10px" }}>{error}</Typography>}
              </Grid>
            </Grid>
          </FormControl>
        )}
    </div>
  );
};

export default Transactions;
