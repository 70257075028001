import { userLogin, userGet } from "../../api/user.api";
import { saveSession } from "../../utils/session";

const submitForm = async (username, password) => {
  try {
    const { token } = await userLogin(username, password);
    const { user } = await userGet(token);

    saveSession({
      id: user.id,
      role: user.role,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
    }, token);
    return { status: true };
  } catch (e) {
    return { status: false, message: e.message };
  }
};

export { submitForm };
