import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import { permissions, menu } from "../../helpers/permissions";

import { getSession } from "../../utils/session";

import useStyles from "./styles";

const SidebarMenu = ({ toggleMenu }) => {
  const history = useHistory();
  const { user } = getSession() || {};

  const routes = user?.role ? permissions[user.role].routes : [];

  const [activeMenuItem, setActiveMenuItem] = useState(
    // eslint-disable-next-line no-unused-vars
    Object.entries(menu).find(([key, value]) => value.path === history.location.pathname)?.[0],
  );

  const classes = useStyles();

  const handleMenuItemClick = (id, path) => {
    setActiveMenuItem(id);
    if (toggleMenu) toggleMenu(false);
    history.push(path);
  };

  return (
    <Box component="nav">
      <MenuList className={classes.container}>
        {routes.map((route) => (
          <MenuItem
            key={route}
            classes={{ selected: classes.selectedMenuItem }}
            selected={route === activeMenuItem}
            onClick={() => handleMenuItemClick(route, menu[route].path)}
          >
            {menu[route].name}
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

SidebarMenu.propTypes = {
  toggleMenu: PropTypes.func,
};

SidebarMenu.defaultProps = {
  toggleMenu: null,
};

export default SidebarMenu;
