import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@material-ui/core/Toolbar";

import TopBar from "../../components/TopBar";
import SidebarMenu from "../../components/SidebarMenu";

import useStyles from "./styles";

const drawerWidth = 200;

const MainLayout = ({ window, children }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const toggleMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    document.title = children.props.title || "";
  }, [children.props.title]);

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TopBar toggleMenu={toggleMenu} title={children.props.title} drawerWidth={drawerWidth} />
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "64px" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={() => toggleMenu()}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: `${drawerWidth}px` },
            }}
          >
            <SidebarMenu drawerWidth={drawerWidth} toggleMenu={toggleMenu} />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: `${drawerWidth}px`, zIndex: 10 },
            }}
            open
          >
            <Toolbar />
            <SidebarMenu drawerWidth={drawerWidth} />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth})px` },
            padding: 0,
            marginLeft: { sm: `${drawerWidth}px` },
          }}
        >
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  window: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  children: PropTypes.node.isRequired,
};

MainLayout.defaultProps = {
  window: undefined,
};

export default MainLayout;
